import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { getFeatureFlags } from 'src/api';
import { FeatureFlag } from 'src/api/types';

export enum FeatureFlags {
  mint_reward = 'mint_reward',
  plug_deposit = 'plug_deposit',
  plug_cata_redemption = 'plug_cata_redemption',
  token_swap = 'token_swap',
  dex = 'dex',
  address_book = 'address_book',
  governance = 'governance',
  governance_attachment = 'governance_attachment',
  governance_extra_fee = 'governance_extra_fee',
}


interface IFeatureFlagContextProps {
  featureFlags: FeatureFlag[];
}

const FeatureFlagContext = createContext<IFeatureFlagContextProps>({
  featureFlags: [],
});

export const FeatureFlagProvider: FC<{ children: ReactNode | ReactNode[] | null }> = ({
  children,
}) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const featureFlags = await getFeatureFlags();
      setFeatureFlags(featureFlags as unknown as FeatureFlag[]);
    };
    fetchFeatureFlags();
  }, []);

  return (
    <FeatureFlagContext.Provider value={{ featureFlags }}>{children}</FeatureFlagContext.Provider>
  );
};

const useFeatureFlagContext = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error(`useFeatureFlagContext must be used in FeatureFlagProvider`);
  }

  return context;
};

export default useFeatureFlagContext;
